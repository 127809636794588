<template>
  <div class="hu_notice_box">
    <van-nav-bar title="帮助说明" left-text="返回" left-arrow fixed placeholder @click-left="$router.push('/personalInfo')">
    </van-nav-bar>
    <van-col :span="24" style="margin-left: 0px;margin-right: 0px;">
      <div v-for="(option,index) in data" v-bind:key="index" style="padding-top: 20px;text-align: left;padding-left: 20px;">
        <a s @click="show_show(option)" style="margin-left: 10px;font-size: 14px;">
          {{option.title}}
        </a>
      </div>

    </van-col>
    <div v-if="data.length==0">
      <van-empty description="暂无" />
    </div>
    <van-pagination v-if="currentPageall>1" v-model="currentPage" :page-count="currentPageall" mode="simple" @change="changepage" />
  </div>
</template>
<script>
import { djarticlelist_type } from '@/api/index'
export default {
  data() {
    return {
      mytype: '',
      data: [],
      currentPage: 1,
      currentPageall: 1,
    };
  },
  created() {
    console.log("load_api");
  },
  mounted() {
    this.load_api();
  },
  methods: {
    load_api(data) {
      console.log("load_api1");
      console.log("$route.params.id", this.$route.params.id);
      this.mytype = this.$route.params.id;
      var dsss = { type: this.mytype };
      if (data) {
        dsss.page = data;
      }
      djarticlelist_type(dsss).then(response => {
        console.log("djarticlelist_type=", response.data);
        this.data = response.data.mydata.data;
        this.currentPageall = response.data.mydata.pageall;
        this.currentPage = response.data.mydata.page;
        //this.pagination.current = 1;//response.data.mydata.m_all 
      });
    },
    changepage(data) {
      this.load_api(data);
    },
    show_show(data) {
      this.$router.push('/infohelp_show/' + data.guid + "?type=" + this.mytype);
    },
  },
};
</script>
<style scoped>
</style>